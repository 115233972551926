.c-pointer {
    cursor: pointer;
}

.img-icon {
    max-width: 200px;
}

.opacity-50 {
    opacity: .5;
}