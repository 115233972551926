// Bootstrap mixins and functions
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "../../scss/volt/variables";

.modal-content {
  .modal-header.m-error {
    background-color: $red;
  }

  .mt-error {
    color: $red;
  }

  .modal-header.m-success {
    background-color: $green;
  }

  .mt-success {
    color: $green;
  }

  .modal-header.m-warn {
    background-color: $yellow;
  }

  .mt-warn {
    color: $yellow;
  }
}
