.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: fixed;
    top: -18px;
    left: -25px;
    z-index: 1000;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

.ribbon::before {
    top: 0;
    right: 0;
}

.ribbon::after {
    bottom: 0;
    left: 0;
}