.expandable-item {
  background: #05a677;
  border-radius: 6px;
  color: white;
  margin: 4px;
  margin-left: 0;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .text-success {
    font-weight: 700;
  }
  
  .products-list {
    background: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #05a677;
    color: #4a5073;
    display: none;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    padding: 10px;
  
    &.expanded {
      display: block;
    }
  
    .title {
      margin-right: 10px;
      font-weight: 400;
    }
  
    .quantity {
      font-weight: 600;
    }
  }
}

